const THROTTLE_THRESHOLD_MS = 5e3;
let lastMessage;
let lastCalledAt = 0;
const throttledLog = (message, config = {}) => {
  const {
    level = "log"
  } = config;
  if (message === lastMessage && Date.now() - lastCalledAt < THROTTLE_THRESHOLD_MS) {
    lastCalledAt = Date.now();
    return;
  }
  lastCalledAt = Date.now();
  lastMessage = message;
  console[level](message);
};
export { throttledLog };
